.app {
  display: flex;
  flex-direction: column;
  height: 100vh;

  background: -moz-linear-gradient(top,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.39) 11%, rgba(0,0,0,0) 23%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.39) 11%,rgba(0,0,0,0) 23%,rgba(0,0,0,0) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.39) 11%,rgba(0,0,0,0) 23%,rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf000000', endColorstr='#00000000',GradientType=0 );
}
