.card-wrapper {
  background-color: var(--dr-white);
  border: 1px solid var(--dr-border);
  box-sizing: border-box;
  border-radius: 6px;
  width: 232px;
  height: 220px;
  padding: var(--ds-spacing-05);
  cursor: pointer;

  .h4 {
    padding-top: var(--ds-spacing-03);
    padding-bottom: var(--ds-spacing-03);
  }

  .caption {
    color: var(--dr-text-muted);
  }
}


.workbench-card {

  .card-wrapper {
    background-color: var(--dr-white);
    border: 1px solid var(--dr-border);
    box-sizing: border-box;
    border-radius: 6px;
    width: 728px;
    height: 100px;
    padding: var(--ds-spacing-05);
    cursor: pointer;

    .h4 {
      padding-top: var(--ds-spacing-03);
      padding-bottom: var(--ds-spacing-03);
      line-height: 18px;
      margin-left: 70px;
      margin-top: -65px;
    }

    .caption {
      color: var(--dr-text-muted);
      margin-left: 70px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .workbench-card {
    .card-wrapper {
      width: 480px;
      height: 130px;
    }
  }
}

@media screen and (max-width: 600px) {
  .workbench-card {
    .card-wrapper {
      width: 232px;
      height: 235px;
      .h4 {
        margin-left: 0px;
        margin-top: 0px;
      }

      .caption {
        margin-left: 0px;
      }
    }
  }
}
