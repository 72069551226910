@import 'src/common-scss/breakpoints.scss';

.footer {
  width: 100%;
  align-self: flex-end;

  &::after {
    content: '';
    display: block;
    background: url('../../assets/images/DR-pattern-full-width.svg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 10px;
  }

  .footer-content {
    max-width: 1274px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 var(--ds-spacing-07);

    .actions-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--ds-spacing-07) var(--ds-spacing-04);

      .actions {
        display: flex;
        align-items: center;
      }

      .caption {
        color: var(--dr-white);
        padding-left: var(--ds-spacing-06);
        padding-right: var(--ds-spacing-03);
      }

      .action-button {
        display: flex;

        .white-button {
          cursor: pointer;
          padding: 0 var(--ds-spacing-03);
          text-decoration: none;
          color: var(--dr-white);
          &:hover,
          &:focus {
            color: var(--dr-secondary-hover);
          }
        }
      }
    }
  }

  @include break-to-device(mobile) {
    position: initial;
    min-height: auto;
    .footer-content {
      padding: 0 var(--ds-spacing-06);
      .actions-content {
        justify-content: center;
        .actions {
          flex-direction: column;
          .caption {
            padding-bottom: var(--ds-spacing-06);
          }
        }
      }

      .selectbox {
        display: none;
      }

      .robot-icon {
        display: none;
      }
    }
  }
}
