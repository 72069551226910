:root {

  // midnight-gray
  --mg-header-bg-color: #1d2226;
  --mg-header-border-color: #2a3036;
  --mg-header-item-text-color: #e3e6e8;
  --mg-header-item-text-active-color: #fff;
  --mg-header-item-text-disabled-color: #727b84;
  --mg-header-item-bg-active-color: #2a3036;
  --mg-header-secondary-items-hover-color: #46a4e7;
  --mg-area-selector-active-bg: #2a3036;
  --mg-area-selector-active-color: #e3e6e8;
  --mg-accent-primary: #3e91cd;
  --mg-page-background-color: #181d21;
  --mg-base-tile-hover-bg: #3e454d;
  --mg-base-border-color: #2a3036;
  --mg-text-primary: #e3e6e8;
  --mg-text-secondary: #8d959e;

  --light-header-text-color: #464e5b;

  --mg-button-primary-bg: #3e91cd;

  // primary orange color
  --dr-primary: #ff5600;
  --dr-primary-hover: #ff8200;
  --dr-primary-focus: #e64d00;

  // primary blue color
  --dr-secondary: #2d8fe2;
  --dr-secondary-hover: #2caeff;
  --dr-secondary-focus: #1372c3;

  // Other buttons colors
  --dr-grey-button: #bcbcbe;
  --dr-white: #ffffff;

  // Input
  --dr-input-placeholder: #c4c8c9;
  --dr-input-placeholder-focused: #81868a;
  --dr-input-bg: #252b2f;

  // Background
  --dr-dark-background: #0a0a0c;
  --dr-light-background: #f2f6f9;
  --dr-gray-out-background: #b3b5bb;

  // Borders
  --dr-border: #d0d8e2;
  --dr-tags: #e1e8f0;

  // text
  --dr-text-muted: #53718f;
  --dr-text-dark: #23232d;
  --dr-text-gray-out: #9facbc;

  // hovers
  --dr-dropdown-hover: #f1f5f8;

  // language dropdown
  --box-shadow: rgba(54, 77, 114, 0.18);
  --dr-language-selector-bg: rgba(148, 150, 175, 0.16);

  // footer
  --footer-border: rgba(255, 255, 255, 0.16);

  //sidebar
  --sidebar-overlay: rgba(0, 0, 0, 0.8);

  // code block
  --dr-code-block: #f5f5f5;

  //admonitions
  --admonition-tip: #00bfa5;
  --admonition-tip-rgb: rgba(0, 191, 165, 0.1);

  --admonition-note: #448aff;
  --admonition-note-rgb: rgba(68, 138, 255, 0.1);

  --admonition-info: #53718f;
  --admonition-info-rgb: rgba(83, 113, 143, 0.1);

  --admonition-warning: #ff9100;
  --admonition-warning-rgb: rgba(255, 145, 0, 0.1);

  --admonition-faq: #1372c3;
  --admonition-faq-rgb: rgba(19, 114, 195, 0.1);

  // fix dropdown border color in sidebar
  --dropdown-border-color: #ffffff;
}
