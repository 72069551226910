@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  src: url('../assets/fonts/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/Inter-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('../assets/fonts/Inter-Black.woff2') format('woff2');
}

