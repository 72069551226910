.language-switcher-icons {
  background: var(--dr-white);
  box-shadow: 0 6px 8px var(--box-shadow), 0 2px 4px var(--box-shadow);
  border-radius: 6px;

  ul.dropdown-menu-list .drop-item button {
    color: var(--dr-text-dark);
    cursor: pointer;
    height: 40px;

    &:hover {
      background: var(--dr-dropdown-hover);
      color: var(--dr-text-dark);
    }
  }

  .drop-item-content-wrap {
    .drop-item-title-content {
      display: flex;

      .english-icon {
        background-image: url('/assets/images/english.svg');
        width: 20px;
        height: 20px;
      }

      .japanese-icon {
        background-image: url('/assets/images/japanese.svg');
        width: 20px;
        height: 20px;
      }
    }
  }
}

.language-trigger-icons {
  &.lg {
    height: 40px;
  }

  &.dropdown-selectbox-trigger {
    border-radius: 6px;
    background-color: var(--dr-language-selector-bg);
    color: var(--dr-white);
    border: none;
    box-shadow: none;

    &:focus {
      background-color: var(--dr-input-bg);
      color: var(--dr-white);
      box-shadow: none;
    }
  }

  .selected-item {
    .fa-chevron-down {
      color: var(--dr-white);
      fill: white;
    }

    .drop-item-title-content {
      display: flex;
      align-items: center;
      .drop-icon {
        margin-right: var(--ds-spacing-02);
      }
      .english-icon {
        background-image: url('/assets/images/english.svg');
        width: 20px;
        height: 20px;
      }

      .japanese-icon {
        background-image: url('/assets/images/japanese.svg');
        width: 20px;
        height: 20px;
      }
    }
  }
}
