@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('../assets/fonts/roboto-thin.woff2') format('woff2'),
    url('../assets/fonts/roboto-thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/roboto-light.woff2') format('woff2'),
    url('../assets/fonts/roboto-light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/roboto-regular.woff2') format('woff2'),
    url('../assets/fonts/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/roboto-italic.woff2') format('woff2'),
    url('../assets/fonts/roboto-italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/roboto-medium.woff2') format('woff2'),
    url('../assets/fonts/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/roboto-bold.woff2') format('woff2'),
    url('../assets/fonts/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('../assets/fonts/roboto-black.woff2') format('woff2'),
    url('../assets/fonts/roboto-black.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/RobotoMono-Regular.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/RobotoMono-Italic.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/RobotoMono-Bold.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 100;
  font-style: normal;
  src: url('../assets/fonts/RobotoMono-Thin.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/RobotoMono-Light.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/RobotoMono-Medium.woff2') format('woff2'),
    url('../assets/fonts/RobotoMono-Medium.woff') format('woff');
}
