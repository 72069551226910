@import 'src/common-scss/breakpoints.scss';

.page-content {
  margin: 0 var(--ds-spacing-04);

  .title {
    padding-top: var(--ds-spacing-09);
    padding-bottom: var(--ds-spacing-03);
    color: var(--dr-white);
  }

  .subtitle {
    padding: var(--ds-spacing-03) 0;
    color: var(--dr-white);
  }

  .search-container {
    display: flex;
    padding-top: var(--ds-spacing-03);
    padding-bottom: var(--ds-spacing-05);

    .algolia-search {
      width: 100%;
      .ais-SearchBox-input {
        font-family: var(--global-font-family);
        color: var(--input-color-focused);
        border-radius: 6px;
        background-color: var(--dr-white);
        width: 100%;
      }

      @include break-to-device(mobile) {
        & {
          width: 100%;
          .search-input {
            width: 100%;
          }
        }
      }

      @include break-from-device(tablet) {
        .ais-SearchBox-form {
          width: 100%;
        }
      }
    }

    input {
      &::placeholder {
        color: var(--dr-text-muted);
      }
      &:focus {
        &::placeholder {
          color: var(--dr-text-muted);
        }
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: var(--ds-spacing-08);
    justify-content: center;

    .card-link {
      margin: var(--ds-spacing-03) var(--ds-spacing-02);
    }

    .workbench-card {
      margin: var(--ds-spacing-03) var(--ds-spacing-02);
    }
  }

  .docs-assist-search-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
    background: var(--ds-alpine-light-90);
    padding: var(--ds-spacing-06) var(--ds-spacing-06);
  }

  .search-title {
    color: var(--ds-alpine-light-20);
    padding-right: var(--ds-spacing-04);
    margin-left: var(--ds-spacing-02);
  }

  .docs-assist-cards {
    border-radius: 0 0 6px 6px;
    background: var(--ds-alpine-light-90);
    padding: 0 var(--ds-spacing-06) var(--ds-spacing-06);
  }

  .middle {
    width: 744px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;
  }

  @include break-to-device(tablet) {
    .middle {
      width: 600px;
    }
  }

  @include break-to-device(mobile) {
    margin: 0;
    .middle {
      width: 280px;
    }
  }
}
