@import '@datarobot/design-system/styles/index.css';
@import '@datarobot/design-system/styles/themes/alpine-light.css';
@import 'common-scss/colors.scss';
@import 'common-scss/fontface-roboto.scss';
@import 'common-scss/fontface-titillium-web.scss';
@import 'common-scss/fontface-inter.scss';
@import 'common-scss/typography.scss';
@import 'common-scss/breakpoints.scss';
@import 'common-scss/preview-badge.scss';

html,
body {
  height: 100%;
  width: 100%;
  --container-max-width: 1274px;
}

body {
  margin: 0;
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dr-dark-background);
}

main {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 110px;
  box-sizing: border-box;
  width: 100%;
  max-width: calc(var(--container-max-width) + 2 * var(--ds-spacing-07));
  padding: 0 var(--ds-spacing-07);
  flex: 1;
}

code {
  font-family: var(--global-font-family-mono);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
menu {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@include break-to-device(mobile) {
  main {
    padding: 0 var(--ds-spacing-05);
    margin-bottom: 0;
  }
}
