:root {
  --font-family-inter: 'Inter', sans-serif;
  --font-family-monospace: 'Roboto Mono', monospace;
  --global-font-family: var(--font-family-inter); 
  --global-font-family-mono: var(--font-family-monospace);
}

@mixin text-setup($font-family, $font-weight, $font-size, $line-height: 1.5) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

.subtitle {
  @include text-setup(var(--global-font-family), normal, 24px, 38px);
}

.paragraph-regular {
  @include text-setup(var(--global-font-family), normal, 16px, 26px);
}

.paragraph-bold {
  @include text-setup(var(--global-font-family), bold, 16px, 26px);
}

// Menu item - Links/Buttons
.action-item {
  @include text-setup(var(--global-font-family), normal, 16px, 24px);
}
