.no-filter-results {
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--ds-spacing-10);

    .description {
      margin-top: var(--ds-spacing-06);
      text-align: center;
    }

    .show-all-button {
      margin-top: var(--ds-spacing-07);
      border-radius: 6px;
      background: var(--dr-primary);
    }
  }
}
