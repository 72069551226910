@import 'src/common-scss/breakpoints.scss';
@import 'src/common-scss/colors.scss';

.search-page {
  margin: 60px;
  border-radius: 6px;
  .ais-SearchBox-input {
    border-radius: 6px;
    margin: 20px 20px 0 20px;
  }
  .filter, .ais-Stats {
    margin: 0 var(--ds-spacing-05);
  }
}

.algolia-search {
  background-color: white;
  margin: 60px auto 0;
  border-radius: 6px;

  &.search-page {
    &.release {
      margin: 20px auto 0;
    }
  }

  .ais-SearchBox-form {
    display: flex;
    position: relative;
    width: 100%;

    .ais-SearchBox-input {
      width: 100%;
      outline: none;
      border: #464F5C 1px solid;
    }
  }

  input {
    padding: 8px 40px;
    font-size: 1.2rem;
    line-height: 20px;

    &::placeholder {
      color: var(--dr-input-placeholder);
    }

    &:focus {
      box-shadow: var(--dr-secondary-hover) 0 0 0 2px inset;

      &::placeholder {
        color: var(--dr-text-muted);
      }
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    background: transparent;
    border: none;
    position: absolute;
  }
  button {
    svg {
      height: 14px;
      width: 14px;
      fill: var(--dr-text-muted);
    }
  }

  .ais-SearchBox-submit {
    top: 30px;
    left: 20px;
    padding: 2px var(--ds-spacing-03) 0 var(--ds-spacing-03);
  }
  .ais-SearchBox-reset {
    top: 30px;
    right: 28px;
    cursor: pointer;
  }

  .main-page-search {
    top: 10px;
    left: 0;
  }

  .main-page-cancel {
    top: 10px;
    right: 0;
    padding: 2px var(--ds-spacing-03) 0 var(--ds-spacing-03);
  }

  input[type='search']:placeholder-shown ~ .ais-SearchBox-reset {
    visibility: hidden;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search-hits {
    display: flex;
    flex-direction: column;
    margin: 0 var(--ds-spacing-05);
    overflow-wrap: break-word;

    em.highlight {
      font-style: normal;
      font-weight: bold;
      background: #81FBA5;
    }

    .hit {
      display: flex;
      flex-direction: column;
      margin: 1rem 0;

      .hit-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .hit-icon {
          flex: 1 0 0;

          svg {
            height: 0.8rem;
            width: 0.8rem;
          }
        }

        .hit-path {
          flex: 10 1 80%;
        }

        .breadcrumbs {
          color: var(--input-color-focused);
          display: block;
          font-size: 0.7rem;
          margin: var(--ds-spacing-01) 0;

          span {
            &.delim {
              font-size: 14px;
              padding: 0 0.5rem;
              margin: 0;
            }
          }
        }

        .hit-badge {
          flex: 1 0 0;
          text-align: right;
        }
      }

      .hit-item a {
        color: #007DD6;
        text-decoration: underline;
        font-size: 1.2rem;
      }
      .hit-item a:visited {
        color: #3D29B3;
      }

      .hit-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        overflow-wrap: anywhere;
      }

      .hit-content {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .ais-Pagination {
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 40%;
    @media (max-width: 768px) {
      max-width: 80%;
    }


    .ais-Pagination-list {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 16px;
      line-height: 24px;
      color: var(--md-typeset-muted-color);
    }

    .ais-Pagination-item--selected {
      a {
        color: var(--md-typeset-color);
        font-weight: bold;
        background: var(--md-primary-bg-color);
        box-shadow: 0 6px 8px rgba(54, 77, 114, 0.08),
          0 2px 4px rgba(54, 77, 114, 0.18);
        border-radius: 4px;
        padding: 8px 12px;
      }
    }

    .ais-Pagination-item--disabled {
      color: var(--md-header-button);
      opacity: 0.3;
    }

    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage,
    .ais-Pagination-item--lastPage {
      font-size: 20px;
    }
  }
}
