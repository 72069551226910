@import "src/common-scss/breakpoints.scss";

.sidebar {
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    right: 48px;
    top: 34px;

    svg {
      width: 30px !important;
      height: 30px !important;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: var(--dr-white);
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background:  var(--dr-white);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    top: 18px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: var(--dr-text-dark);
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: var(--dr-white);
  }


  /* Wrapper for item list */
  .bm-item-list {
    color: var(--dr-text-dark);
  }

  /* Individual item */
  .bm-item {
    display: block;
    border-bottom: 1px solid var(--dr-tags);
    padding: var(--ds-spacing-03) var(--ds-spacing-07);
  }

  .link {
    display: block;
    font-weight: 600;
    width: 100%;

    &:hover {
      color: var(--dr-secondary-hover);
    }
  }

  .active {
    color: var(--dr-secondary);
  }

  /* Styling of overlay */
  .bm-overlay {
    background: var(--sidebar-overlay);
  }

  .search-input-sidebar {
    input {
      height: 28px;
      background-color: transparent;
      border-radius: 6px;
      &:hover {
        background-color: transparent;
      }
    }
  }

  .language-switcher {
    .language-trigger-icons {
      &.dropdown-selectbox-trigger {
        background-color: transparent;
        color: var(--dr-text-dark);
        width: 150px;
      }
    }

    .fa-chevron-down {
      color: var(--dr-text-dark);
    }
  }

  @include break-to-device(mobile) {
    .bm-burger-button {
      right: 24px;
      top: 34px;
    }
  }
}

[data-popper-placement="bottom"] {
  z-index: 10001;
}
