.filter {
  display: flex;
  padding: var(--ds-spacing-04) 0;
  align-items: baseline;
  .button-group {
    display: flex;
    flex-wrap: wrap;
    button {
      padding: var(--ds-spacing-01) var(--ds-spacing-03);
      font-weight: 600;
      height: 34px;
      margin: 0 2px 4px 2px;
      border-radius: 6px;
      &.command {
        color: #23232D;
        border: 2px solid  #d0d8e2;
        &:hover {
          background-color: #F2F6F9;
        }
      }
      &.primary {
        background: var(--dr-secondary);
        &:hover {
          background: var(--dr-secondary-hover);
        }
      }

    }
  }
}

.ais-Stats {
  font-size: 16px;
  line-height: 19px;
}
