@import 'src/common-scss/breakpoints.scss';

.lastupdated-container {
  width: 100%;
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .lastupdated-wrapper {
    max-width: var(--container-max-width);
    padding: 0 var(--ds-spacing-07);
    width: 100%;
    .lastupdated-content {
      @include break-to-device(mobile) {
        text-align: center;
        padding: var(--ds-spacing-05) 0;
      }
      .date-lead-in {
        color: var(--dr-white);
        opacity: 0.5;
        margin-right: var(--ds-spacing-01);
      }
      .date-text {
        color: var(--dr-white);
      }
    }
  }
}
