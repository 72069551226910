@import 'src/common-scss/breakpoints.scss';

.search-content {
  background: var(--dr-white);
  color: var(--dr-text-dark);
  width: 1000px;
  margin-top: var(--ds-spacing-09);
  min-height: auto;

  .dr-modal-body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0px;
    overflow: hidden;
  }

  .horizontal-divider {
    border-top: 1px solid var(--dr-light-background);
    display: none;
  }

  .search-results {
    display: flex;
    overflow: hidden;

    .ais-Stats {
      padding: 0 var(--ds-spacing-04) var(--ds-spacing-04);
      font-size: 16px;
      line-height: 19px;
    }

    .search-items {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      height: 75vh;

      .search-items-list {
        height: 60vh;
        overflow: auto;

        @include break-from-device(tablet landscape) {
          .selected-item {
            background: var(--dr-light-background);
            color: var(--dr-text-muted);
          }
        }
      }

      .item-icon {
        display: none;
      }

      .section-name {
        display: none;
      }

      .preview {
        height: 67px;
        overflow: hidden;
        max-width: 624px;

        @media only screen and (min-width: 390px) {
          table {
            margin: var(--ds-spacing-02) 0;
            border-radius: 6px;
            border-collapse: collapse;

            th {
              background-color: var(--dr-tags);
              color: var(--dr-dark-background);
              font-weight: bold;
              text-transform: uppercase;
            }

            th,
            td {
              border: 1px solid var(--dr-border);
              padding: var(--ds-spacing-02);
            }
          }
        }

        code,
        pre {
          overflow: auto;
          font-family: var(--global-font-family-mono);
          font-size: 12px;
          line-height: 1.4;
          background-color: var(--dr-tags);
          padding: var(--ds-spacing-01);
          border-radius: 6px;
        }

        pre > code {
          display: block;
        }
      }
    }
    .search-preview {
      flex-basis: 0%;
      height: 75vh;
      overflow: auto;
      box-shadow: inset 1px 1px 2px rgba(141, 149, 156, 0.08),
        inset 2px 2px 8px rgba(141, 149, 156, 0.11);

      .no-filter-results-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    .uppercase {
      background: var(--dr-light-background);
      color: var(--dr-text-muted);
      padding: var(--ds-spacing-01) var(--ds-spacing-07);
    }
  }

  @include break-from-device(tablet landscape) {
    .search-results {
      .search-preview {
        flex-basis: 60%;
      }

      .search-items {
        flex-basis: 40%;

        .preview {
          display: none;
        }

        .section-name {
          display: block;
        }

        .item-icon {
          display: block;
          &:empty {
            min-width: 32px;
          }
        }

        .horizontal-divider {
          display: block;
        }
      }
    }
  }

  @include break-to-device(mobile) {
    .search-results {
      .filter {
        .paragraph-bold {
          display: none;
        }
      }
      .search-items {
        .preview {
          max-width: 260px;
        }
        @media only screen and (max-width: 390px) {
          .preview {
            max-width: 180px;
          }
        }
      }
    }
  }


  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 var(--ds-spacing-07);
    border-radius: 8px 8px 0 0;

    .fa-search {
      path {
        fill: var(--dr-secondary);
      }
    }

    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    .search-form {
      width: 100%;
      margin-left: var(--ds-spacing-05);
    }

    .search-input {
      width: 100%;
      height: 60px;
      outline: none;
      border: none;
      background-color: transparent;
      margin-bottom: var(--ds-spacing-02);
    }

    .action-buttons {
      display: flex;
      align-items: center;

      .cancel-button {
        font-weight: 600;
        color: var(--dr-text-muted);
        &:hover {
          color: var(--dr-text-dark);
          opacity: 0.7;
        }
      }

      .vertical-divider {
        border-left: 1px solid var(--dr-border);
        height: 40px;
        margin: 0 var(--ds-spacing-04);
      }
    }
  }

  .ais-Pagination {
    padding: var(--ds-spacing-04) var(--ds-spacing-07);

    .ais-Pagination-list {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 16px;
      line-height: 24px;
      color: var(--dr-text-muted);
    }

    .ais-Pagination-link--selected {
      color: var(--dr-text-dark);
      font-weight: bold;
      background: var(--dr-white);
      padding: var(--ds-spacing-02) var(--ds-spacing-03);
      box-shadow: 0 6px 8px rgba(54, 77, 114, 0.08),
        0 2px 4px rgba(54, 77, 114, 0.18);
      border-radius: 4px;
    }

    .ais-Pagination-item--disabled {
      color: var(--dr-grey-button);
      opacity: 0.7;
    }

    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage,
    .ais-Pagination-item--lastPage {
      font-size: 20px;
    }
  }

  .loading {
    margin-top: 65px;
    text-align: center;
    margin-right: 6px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.r-modal-overlay {
  align-items: flex-start;
  .r-modal-content {
    padding: 0;
    animation-name: none;
  }
}

.md-dialog {
  position: fixed;
  right: var(--ds-spacing-04);
  bottom: var(--ds-spacing-04);
  left: initial;
  z-index: 3;
  min-width: 222px;
  padding: var(--ds-spacing-02) var(--ds-spacing-03);
  background-color: var(--dr-light-background);
  border-radius: 2px;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0ms 400ms, opacity 400ms;
  pointer-events: none;

  // Dialog in open state
  &[data-md-state='open'] {
    transform: translateY(0);
    opacity: 1;
    transition: transform 400ms cubic-bezier(0.075, 0.85, 0.175, 1),
      opacity 400ms;
    pointer-events: initial;
  }
}
