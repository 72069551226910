// Device-specific breakpoints
$break-devices: (
  mobile: (
    portrait: 220px 479px,
    landscape: 480px 719px,
  ),
  tablet: (
    portrait: 720px 959px,
    landscape: 960px 1219px,
  ),
  screen: (
    small: 1220px 1599px,
    medium: 600px 1999px,
    large: 2000px,
  ),
);

///
/// A minimum media query device breakpoint
///
@mixin break-from-device($device) {
  @if type-of($device) == string {
    $device: $device,;
  }
  @if type-of($device) == list {
    $breakpoint: break-select-device($device);
    $min: nth($breakpoint, 1);

    @media screen and (min-width: $min) {
      @content;
    }
  } @else {
    @error "Invalid device: #{$device}";
  }
}

///
/// Select minimum and maximum widths for a device breakpoint
///
@function break-select-device($device) {
  $current: $break-devices;
  @for $n from 1 through length($device) {
    @if type-of($current) == map {
      $current: map-get($current, nth($device, $n));
    } @else {
      @error "Invalid device map: #{$devices}";
    }
  }
  @if type-of($current) == list or type-of($current) == number {
    $current: (default: $current);
  }
  @return break-select-min-max($current);
}

///
/// Choose minimum and maximum device widths
///
@function break-select-min-max($devices) {
  $min: 1000000px;
  $max: 0px;
  @each $key, $value in $devices {
    @while type-of($value) == map {
      $value: break-select-min-max($value);
    }
    @if type-of($value) == list {
      @each $number in $value {
        @if type-of($number) == number {
          $min: unquote('min(#{$number}, #{$min})');
          @if $max {
            $max:  unquote('max(#{$number}, #{$max})');
          }
        } @else {
          @error "Invalid number: #{$number}";
        }
      }
    } @else if type-of($value) == number {
      $min: unquote('min(#{$value}, #{$min})');
      $max: null;
    } @else {
      @error "Invalid value: #{$value}";
    }
  }
  @return $min, $max;
}


///
/// A maximum media query device breakpoint
///
@mixin break-to-device($device) {
  @if type-of($device) == string {
    $device: $device,;
  }
  @if type-of($device) == list {
    $breakpoint: break-select-device($device);
    $max: nth($breakpoint, 2);

    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @error "Invalid device: #{$device}";
  }
}
