.search-assist-container {
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: stretch;

  .search-header {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: var(--ds-spacing-04);
    padding-left: var(--ds-spacing-06);
    margin: 0;
    font-family: var(--global-font-family);
    font-size: 22px;
  }

  .search-box-container {
    flex-shrink: 0;
    padding-left: var(--ds-spacing-06);
    padding-right: var(--ds-spacing-06);
    padding-bottom: var(--ds-spacing-06);
    padding-top: 0;

    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      margin: 0px;

      .search-input {
        flex-grow: 5;
        padding: 0px;
        margin: 0px;
      }

      .action-buttons {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .data-sources {
      white-space: nowrap;
      font-size: 12px;
      white-space: nowrap;
      padding: 0;
    }
  }

  .search-results {
    flex-grow: 1;
    display: flex;
    flex-flow: column;

    .line-break > p {
      white-space: pre-wrap;
    }

    .bot {
      padding: 0px 20px;
      margin-bottom: 20px;

      .bot-title,
      .citation-page-title {
        margin-bottom: 12px;
        word-break: break-word;
      }
      li {
        margin-bottom: 6px;
      }
      .radiobutton + label {
        color: var(--dr-text-dark);
      }
    }

    .bot-citations {
      .collapsible-panel {
        margin-bottom: 10px;
      }
      iframe {
        display: block;
        width: 100%;
        height: 500px;
      }

      .open-documentation-link {
        color: var(--link-color); // answer link
        margin-right: auto;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .bot-answer {
      padding-top: 15px;

      pre > code {
        font-size: 12px;
        font-family: var(--global-font-family-mono);
        display: block;
        background-color: var(--page-background-color);
        padding: 12px;
        word-break: break-all;
        text-wrap: wrap;
        font-style: normal;
      }
      p > code {
        background-color: var(--ds-alpine-light-60);
        border-radius: 6px;
        font-family: var(--global-font-family-mono);
        font-size: 12px;
        overflow: auto;
        padding: var(--ds-spacing-01);
      }

      p > a {
        color: var(--link-color); // answer link
      }
    }

    .bot-no-answer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: var(--ds-spacing-10);

      .no-answer-icon {
        height: 52px;
        width: 52px;
      }
    }

    .bot-confidence {
      .cap {
        text-transform: capitalize;
      }
    }

    .bot-feedback {
      .bot-feedback-action-bar {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .button.primary {
          background-color: #297cb9;
          margin: 10px 5px;
          border-radius: 4px;
          &:hover,
          &:focus {
            background-color: #184c7d;
          }
          &:disabled {
            opacity: 0.5;
            color: #fff;
            background-color: #184c7d;
          }
        }
        .button.primary.loading {
          background-color: transparent;
          border: 0px;
        }
      }
      .thanks {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px 0;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .blocked {
      a {
        color: var(--dr-secondary);
      }
    }
  }

  .tab-docs-assist-preview-badge {
    display: inline-flex;
    align-items: center;
  }
}

.search-assist-container.classic-search {
  background-color: var(--dr-light-background);
  .search-header {
    background-color: #fff;
    border-bottom: 1px solid var(--ds-dark-20);

    .header-buttons {
      display: flex;

      .header-close-search-modal {
        margin: 0 var(--ds-spacing-02);
        padding: var(--ds-spacing-02) var(--ds-spacing-04);
      }

      .header-assist-button {
        background-color: transparent;
        color: var(--light-header-text-color);
        border: 0px;
        line-height: initial;
        &:hover {
          border-radius: 6px;
          color: #fff;
          background-color: var(--dr-secondary);
        }
      }

      .header-search-button.active {
        border-radius: 6px;
        background-color: #fff;
        border: 0px;
        color: var(--light-header-text-color);
        line-height: initial;
        &:hover {
          border-radius: 6px;
          background-color: #fff;
        }
      }
    }
  }
  .search-box-container {
    .search-box {
      .action-buttons {
        .button.round-icon-button {
          color: var(--button-secondary-color);
        }
      }
    }
  }
  .search-results {
    flex-grow: 1;
    background-color: #fff;
    display: flex;

    flex-flow: row;
  }
}

.search-assist-container.docs-assist {
  background-color: var(--dr-light-background);
  .search-header {
    background-color: #fff;
    border-bottom: 1px solid var(--ds-dark-20);

    .header-buttons {
      display: flex;

      .header-close-search-modal {
        margin: 0 var(--ds-spacing-02);
        padding: var(--ds-spacing-02) var(--ds-spacing-04);
      }

      .header-search-button {
        background-color: transparent;
        border: 0px;
        line-height: initial;

        &:hover {
          border-radius: 6px;
          background-color: var(--dr-secondary);
        }
      }

      .header-assist-button.active {
        border-radius: 6px;
        background-color: var(--ds-dark-50);
        border: 0px;
        line-height: initial;

        &:hover {
          border-radius: 6px;
          background-color: var(--ds-dark-50);
        }
      }
    }
  }
  .search-box-container {
    height: auto;
    .search-box {
      #modal-prompt-input {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;
        margin: 0px;
        padding: 10px 0px;
        transition: none;
        box-shadow: none;
        font-family: var(--global-font-family);
        font-size: 22px;
        font-weight: normal;
        line-height: 38px;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
          background-color: transparent;
        }
      }
    }
    .data-sources {
      color: var(--ds-dark-30);
    }
  }
  .search-results {
    .bot-citations {
      padding-top: 15px;
      padding-bottom: 20px;
      border-top: 1px solid var(--mg-header-border-color);
      .collapsible-panel {
        border-color: var(--mg-header-border-color);
      }
    }
  }
}

.dr-modal-body .bot-answer p {
  margin-bottom: 12px;
}

.collapsible-panel-standalone-inner-content p,
.dr-modal-body .bot-answer p {
  margin-bottom: 12px;
}

.citation {
  display: flex;
  flex-flow: column;
}

.search-assist-page {
  background-color: #fff;
  margin: 0 auto;
}
