@import 'src/common-scss/breakpoints.scss';

.header {
  z-index: 1;
  top: 0;
  width: 100%;

  .sidebar {
    display: none;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    max-width: 1274px;
    padding: 0 var(--ds-spacing-07);

    .logo {
      display: flex;
      padding: var(--ds-spacing-04) var(--ds-spacing-02);
      margin: var(--ds-spacing-03) var(--ds-spacing-02);
    }

    .first-level-navigation {
      display: flex;
      justify-content: flex-end;
      margin: 0 var(--ds-spacing-02);

      .external {
        display: inline-block;
        cursor: pointer;
        padding: var(--ds-spacing-03) var(--ds-spacing-02) var(--ds-spacing-01);
        margin: var(--ds-spacing-02) var(--ds-spacing-01);
        text-decoration: none;
        color: var(--dr-white);
        &:hover,
        &:focus {
          color: var(--dr-grey-button);
        }
      }
      .special {
        display: inline-block;
        cursor: pointer;
        padding: var(--ds-spacing-03) var(--ds-spacing-02) var(--ds-spacing-01);
        margin: var(--ds-spacing-02) var(--ds-spacing-01);
        text-decoration: none;
        color: var(--dr-primary);
        &:hover {
          color: var(--dr-primary-hover);
        }
        &:focus {
          color: var(--dr-primary-focus);
        }
      }
    }
    .second-level {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: var(--ds-spacing-02) var(--ds-spacing-01) var(--ds-spacing-01);
      padding-right: var(--ds-spacing-01);

      .second-level-navigation {
        display: flex;
        .white-button {
          display: inline-block;
          cursor: pointer;
          padding: 0 var(--ds-spacing-02);
          margin: var(--ds-spacing-01);
          color: var(--dr-white);
          text-decoration: none;
          font-weight: 600;
          &:hover {
            color: var(--dr-secondary-hover);
          }
          &:focus {
            color: var(--dr-secondary-focus);
          }
        }

        .active {
          color: var(--dr-secondary);
        }
      }
    }
  }

  @include break-to-device(tablet) {
    .sidebar {
      display: initial;
    }
    .header-container {
      padding: 0 var(--ds-spacing-07);

      .logo {
        padding: var(--ds-spacing-02);
      }
    }

    .navigations {
      display: flex;
      align-items: center;
      .first-level {
        display: none;
      }

      .second-level {
        .second-level-navigation {
          display: none;
        }
        .algolia-search {
          padding-right: var(--ds-spacing-10);
        }
      }
    }
  }

  @include break-to-device(mobile) {
    .header-container {
      padding: 0 var(--ds-spacing-02);
    }
  }
}
